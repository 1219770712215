import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const loginUser = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.AUTH_LOGIN, payload);
    return data
}

export const logoutUser = async () => {
    const { data } = await apiInstance.post(ENDPOINTS.AUTH_LOGOUT);
    return data
}